// Pop-in
// ===========

export default class PopIn {
  constructor(elem, closeMethod) {
    this.elem = elem;
    this.id = elem.dataset.jsPopin;
    this.closeMethod = closeMethod;
    this.closeBtns = document.querySelectorAll('[data-js-popin-close="' + this.id + '"]');

    if (0 != this.closeBtns.length) {
      const that = this;
      [].forEach.call(this.closeBtns, function(btn) {
        btn.addEventListener('click', function(e) {
          e.preventDefault();
          if ("reload" === that.closeMethod) {
            location.reload();
          } else {
            that.elem.setAttribute('aria-hidden', true);
          }
        })
      })
    }
  }
  show() {
    const openPopIn = new CustomEvent('open.popin');
    this.elem.dispatchEvent(openPopIn);
    const body = document.body;
    body.setAttribute('style', 'overflow: hidden');
    // Display pop-in
    this.elem.setAttribute('aria-hidden', 'false');
    // Set focus
    this.closeBtns[0].focus();
  }
  hide() {
    const body = document.body;
    body.removeAttribute('style', 'overflow: hidden');
    const closePopIn = new CustomEvent('close.popin');
    this.elem.dispatchEvent(closePopIn);
    if ("reload" === this.closeMethod) {
      location.reload();
    } else {
      this.elem.setAttribute('aria-hidden', true);
    }
  }
}
